<template>
  <div class="container mx-2">
    <!-- Breadcrumb  -->
    <va-card>
      <va-card-content>
        <div style="display: flex">
          <va-breadcrumbs color="primary">
            <va-breadcrumbs-item :label="$t('menu.users')" />
            <va-breadcrumbs-item :label="$t('menu.referees')" />
          </va-breadcrumbs>
        </div>
      </va-card-content>
    </va-card>
    <div class="mt-3">
      <div class="flex">
        <va-card stripe stripe-color="primary">
          <va-card-content>
            <h1
              v-if="referees.length == 0"
              class="text-center"
              style="font-size: 32px"
            >
              {{ $t("no_referees") }}
              <va-icon class="material-icons" size="32px"> sports </va-icon>
            </h1>
            <va-list v-else>
              <va-list-item
                v-for="(referee, index) in referees"
                :key="index"
                to=""
              >
                <strong>{{ index + offset + 1 }}.</strong>
                <va-list-item-section avatar>
                  <va-avatar>
                    <img v-if="referee.img" :src="referee.img" />
                    <div v-else>{{ referee.name.substring(0, 1) }}</div>
                  </va-avatar>
                </va-list-item-section>

                <va-list-item-section>
                  <va-list-item-label>
                    {{ referee.name }}
                  </va-list-item-label>

                  <va-list-item-label caption :lines="index + 1">
                    {{ referee.tel }}
                  </va-list-item-label>

                  <va-list-item-label caption :lines="index + 1">
                    {{ referee.email }}
                  </va-list-item-label>
                </va-list-item-section>

                <va-list-item-section icon>
                  <va-icon name="remove_red_eye" color="gray" />
                </va-list-item-section>
              </va-list-item>
            </va-list>
            <va-pagination
              v-if="pages > 1"
              color="#0a4695"
              style="direction: ltr"
              class="float-end"
              v-model="currentPage"
              :visible-pages="3"
              :pages="pages"
            />
          </va-card-content>
        </va-card>
      </div>
    </div>
  </div>
</template>

<script>
import { gql, request } from "graphql-request";
import { GET_REFEREES } from "@/graphql/queries/users";
export default {
  data() {
    return {
      referees: [],
      refereesCount: null,
      pages: null,
      currentPage: 1,
      limit: 5,
      offset: 0,
      loading: true,
    };
  },
  async created() {
    // Pagination Logic
    try {
      const REFEREES_COUNT = gql`
        query {
          UsersCount(where: { user_role_id: 5 }) {
            count
          }
        }
      `;
      const counterResponse = await request(
        this.$store.state.appUrl,
        REFEREES_COUNT
      );
      this.refereesCount = counterResponse.UsersCount.count;
      this.pages = Math.ceil(this.refereesCount / this.limit);
      this.getReferees();
    } catch (error) {
      this.loading = false;
      console.log(error);
      this.$swal
        .fire({
          icon: "warning",
          title: this.$t("errors.network"),
          text: "Try Again in a minute",
          confirmButtonColor: "#023b81",
          confirmButtonText: "Reload",
        })
        .then((result) => {
          if (result.value) {
            location.reload();
          }
        });
    }
  },
  methods: {
    async getReferees() {
      this.loading = true;
      this.offset = (this.currentPage - 1) * this.limit;
      try {
        const response = await request(this.$store.state.appUrl, GET_REFEREES, {
          limit: this.limit,
          offset: this.offset,
        });
        this.referees = response.referees;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$swal
          .fire({
            icon: "warning",
            title: this.$t("errors.network"),
            text: "Try Again in a minute",
            confirmButtonColor: "#023b81",
            confirmButtonText: "Reload",
          })
          .then((result) => {
            if (result.value) {
              location.reload();
            }
          });
      }
    },
  },
  watch: {
    async currentPage() {
      await this.getReferees();
    },
  },
};
</script>

<style></style>
