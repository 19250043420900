import { gql } from "graphql-request";


export const GET_ALL_USERS = gql `
query {
    Users 
    {
      id
      name,
      email,
      tel
    }
  }  
`
export const GET_CLIENTS = gql `
query {
    Users(where:{user_role_id:1}limit:10) 
    {
      id
      name,
      email,
      tel
    }
  }  
`

export const GET_ADMINS = gql `
query($limit : Int ,$offset : Int) {
    admins:Users(where:{user_role_id:2}) 
    {
      id
      name,
      email,
      tel
    }
  } 
`

export const GET_EDITORS = gql `
query($limit : Int ,$offset : Int) {
    editors:Users(where:{user_role_id:4}) 
    {
      id
      name,
      email,
      tel
    }
  }
`

export const GET_REFEREES = gql `
query($limit : Int ,$offset : Int) {
    referees:Users(where:{user_role_id:5} ,limit : $limit , offset : $offset) 
    {
      id
      name,
      email,
      tel    
    }
  } 
`
export const GET_ORGANIZERS = gql `
query{
  organizers:UsersHasChamps{
  id,
  name
  }
} 
`